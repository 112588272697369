import Source from './SourceData';

const reverseCoords = (coords) => coords.reverse();

// Sort to group duplicates efficiently
// Map to normalise our data structure
const sourceData = Source
    .sort((a, b) => a.coordinates < b.coordinates ? 1 : -1)
    .map((item, ind, arr) => {

        const {
            status,
            layer,
            name,
            description,
            primarysector,
            postcode,
            firstline,
            directjobcreationforecast,
            indirectjobcreationforecast,
            totaljobcreationforecast,
            defaultcolor,
            checkedcolor,
            icon,
            grantamount,
            startdate,
            coordinates,
        } = item;

        const colours = {
            default: defaultcolor,
            checked: checkedcolor,
        };

        const coOrds = coordinates ? coordinates.split(/,\s?/) : ['52.117468766876065', '0.2260220688837744'];

        // Disperse Map Markers Into Two Rows
        const dupeCoords = Source.filter(source => source.coordinates === item.coordinates);
        if (!!(dupeCoords.length - 1)) {
            let dupeNo = dupeCoords.indexOf(item);
            if (dupeNo % 2) {
                // coOrds[0] = (Number(coOrds[0]) + (dupeNo / 600));
                coOrds[1] = (Number(coOrds[1]) + (++dupeNo / 300));
            } else {
                coOrds[0] = (Number(coOrds[0]) + (2/600));
                coOrds[1] = (Number(coOrds[1]) + (++dupeNo / 300));
            }
        }

        return {
            layer,
            name,
            status,
            description,
            postCode: postcode,
            grantAmount: grantamount,
            startDate: startdate,
            directJobCreationForecast: directjobcreationforecast,
            indirectJobCreationForecast: indirectjobcreationforecast,
            totalJobCreationForecast: totaljobcreationforecast || 0,
            colours,
            coordinates: reverseCoords(coOrds),
            icon,
            primarySector: primarysector,
        };
    });

// Get unique Layers, convert to json, compare, parse via map
const Layers = [
    ...new Set(
        sourceData.map(feature => JSON.stringify({ name: feature.layer, visible: 'visible', colours: feature.colours }))
    )
].map(json => JSON.parse(json));

// Get unique Years
export const Years = [...new Set(sourceData.map(feature => feature.startDate))];
// Get unique Grant Values
export const GrantValues = [...new Set(sourceData.map(feature => feature.grantAmount))];
// Get unique TotalJobCreation Values
export const TotalJobCreationValues = [...new Set(sourceData.map(feature => feature.totalJobCreationForecast))];

// Create our final data structure
export const SourceData = [...Layers]
    .map(layer => ({
        layerName: layer.name,
        visible: layer.visible,
        colours: layer.colours,
        features: sourceData.filter(dummy => dummy.layer === layer.name)
    }));