import 'typeface-lato';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MapBox from './Components/MapBox';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
    },
    typography: {
        fontSize: 13,
        fontFamily: [
            'Lato',
            'serif',
        ].join(','),
    },
});

function App() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <MapBox />
            </MuiThemeProvider>
        </div>
    );
}

export default App;