import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ColorDivider = (props) => {

    const useDividerStyles = makeStyles(() => ({
        divider: {
            background: props.colors.checked,
            height: 6,
        }
    }));

    const classes = useDividerStyles();

    return (
        <Divider classes={{root: classes.divider}}/>
    );
};

export default ColorDivider;