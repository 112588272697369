import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { NumberFormatter } from '../../Utils/Tools';

const LGSlider = withStyles({
    root: {
        color: '#008486',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        '& *': {
            background: '#008486',
            color: '#fff',
        },
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const ValueFilter = ({
    name,
    dataSet,
    valueFilter,
    setValueFilter,
    mapBoxFilter,
}) => {

    const minMark = Math.min(...dataSet);
    const maxMark = Math.max(...dataSet);
    const step = parseInt(maxMark / 100);

    const marks = [minMark, maxMark].map(year => ({
        value: year,
        label: NumberFormatter(year, 1),
    }));

    // IG: This will accurately distribute labels over their respective percentage
    // const marks = dataSet.map(year => ({
    //     value: ( year / ( (maxMark - minMark) - minMark) ) * 100,
    //     label: year,
    // }));

    // SEE: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfilter

    function valueText(value) {
        return `${NumberFormatter(value, 1)}`;
    }

    const handleChange = (event, newValue) => {
        mapBoxFilter();
        setValueFilter(prevState => {
            return {
                ...prevState,
                [name]: newValue
            };
        });
    };

    return (
        <div>
            <Typography gutterBottom id="custom-slider">
                {name}
            </Typography>
            <LGSlider
                id={'range-slider'}
                step={step}
                min={minMark}
                max={maxMark}
                value={valueFilter.hasOwnProperty(name) ? valueFilter[name] : maxMark}
                valueLabelFormat={valueText}
                getAriaValueText={valueText}
                aria-labelledby="custom-slider"
                onChange={handleChange}
                valueLabelDisplay="auto"
                marks={marks}
            />
        </div>
    );
};

export default ValueFilter;