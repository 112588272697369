import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ColorFilterSwitch = ({
    toggleState,
    setToggleState,
    toggleName,
}) => {

    const useSwitchStyles = makeStyles(() => ({
        switchBase: {
            color: '#005759',
            '&$checked': {
                color: '#008486',
            },
            '&$checked + $track': {
                backgroundColor: '#008486',
            },
        },
        checked: {},
        track: {},
    }));

    const switchClass = useSwitchStyles();

    const handleChange = (key, evt) => {
        setToggleState({ ...toggleState, [toggleName]: evt.target.checked });
    };

    return (
        <Switch
            classes={switchClass}
            key={toggleName}
            defaultValue={true}
            checked={ toggleState.hasOwnProperty(toggleName) ? toggleState[toggleName] : true }
            onChange={(evt) => handleChange(toggleName, evt)}
        />
    );
};

export default ColorFilterSwitch;