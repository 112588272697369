const Source = [
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'The Business Growth Service                       ',
        'description': 'Development of a business growth service and other linked activities targetting high value businesses to grow GVA in the Regions',
        'primarysector': 'All',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 5407000,
        'startdate': 2020,
        'directjobcreationforecast': 47,
        'indirectjobcreationforecast': 5892,
        'totaljobcreationforecast': 5939,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Illumina Genomics Accelerator                   ',
        'description': 'Grant based support to start up organisations in the life science field who become part of the Illumina support programme',
        'primarysector': 'Life Science',
        'postcode': 'CB21 6GP',
        'coordinates': '52.11567448970075, 0.22180754950159576',
        'firstline': '19 Granta Park',
        'grantamount': 1000000,
        'startdate': 2020,
        'directjobcreationforecast': 1033,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 1033,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Startcodon Life Science Accelerator',
        'description': 'Start Codon aims to close this equity gap by providing both support (in the form of a six month acceleration programme) (the “Programme”) and seed-funding of between £250,000 - £500,000 (and the Programme and such funding being the “Offering”) to High Potential Companies so that they can perform key experiments, develop their technologies and intellectual property, and expand their team',
        'primarysector': 'Life Science',
        'postcode': 'CB2 0AH',
        'coordinates': '52.17690599904012, 0.14263373385684397',
        'firstline': 'Cambridge Biomedical Innovation Hub',
        'grantamount': 3342250,
        'startdate': 2020,
        'directjobcreationforecast': 1572,
        'indirectjobcreationforecast': 3144,
        'totaljobcreationforecast': 4716,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Ascendal Transport Accelerator',
        'description': 'Development of testing facilities for new transportation technologies, supporting proof of concept to marketing, programme of specific support to start up companies in the field of transport',
        'primarysector': 'Transport',
        'postcode': 'CB24 4UG',
        'coordinates': '52.2778950339239, -0.013911743847992987',
        'firstline': '2 Rowles Way',
        'grantamount': 965000,
        'startdate': 2020,
        'directjobcreationforecast': 2,
        'indirectjobcreationforecast': 200,
        'totaljobcreationforecast': 202,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Medtech Accelerator ',
        'description': 'Share Investment into the Medtech Accelerator, set up to facilitate the early stage development of innovations in the broad area of medical technology (devices, diagnostics, software and eHealth) that meet unmet clinical needs within the NHS',
        'primarysector': 'Life Science',
        'postcode': 'CB24 6WZ',
        'coordinates': '52.24487342687676, 0.16716822449653876',
        'firstline': 'Milton Hall, Ely road',
        'grantamount': 500000,
        'startdate': 2016,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 0
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Peterborough & Fens Smart Manufacturing Association                                ',
        'description': 'OP intends to establish the Smart Manufacturing Association (SMA) (Dig 1) as a JV with CPCA, it will transition to a sustainable, self-financing model ultimately funded through corporate partnerships, fee paying members chargeable services. ',
        'primarysector': 'Business Growth',
        'postcode': 'PE2 8AN',
        'coordinates': '52.5646172949238, -0.24214661384933658',
        'firstline': 'Allia Future Business Centre',
        'grantamount': 715000,
        'startdate': 2020,
        'directjobcreationforecast': 143,
        'indirectjobcreationforecast': 242,
        'totaljobcreationforecast': 385,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Terraview Company Expansion',
        'description': 'Loan to support the fit out costs of a new research facility on the Cambridge Research Park Enterprise Zone',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'CB4 0DS',
        'coordinates': '52.23472055439755, 0.15261535730009695',
        'firstline': '1  Enterprise Cambridge Research Park',
        'grantamount': 120000,
        'startdate': 2018,
        'directjobcreationforecast': 15,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 15,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Aerotron Company Expansion',
        'description': 'Support to develop phase 2 of the relocation to Chatteris and the development of the composite repair training facility',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'PE16 6TT',
        'coordinates': '52.46429734905149, 0.04896655777744375',
        'firstline': 'Spitfire House, Fenton way',
        'grantamount': 1400000,
        'startdate': 2020,
        'directjobcreationforecast': 120,
        'indirectjobcreationforecast': 15,
        'totaljobcreationforecast': 135,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 16
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Agri-Tech Growth Initiative',
        'description': 'The Eastern Agri-Tech Growth Initiative provides a boost to the food, drink and horticulture sectors by supporting businesses looking to invest in specialist equipment, new markets, ways to improve productivity and efficiency, as well as Research and Development. There are two funding streams - a Growth Fund for grants of between £10,000 and £150,000 to support improvements in productivity/efficiency; and the R&D Fund which provides grants of between £10,000 and £60,000 to support the development of innovative ideas, products and technology. Matched by the SMEs themselves, the Growth Deal funding attracts significant leverage and will create a number of new jobs. ',
        'primarysector': 'AgriTech',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 3036252,
        'startdate': 2015,
        'directjobcreationforecast': 300,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 300,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 260
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Growing Places Fund Extension',
        'description': 'This is a £16.6m project pot as a successor to the Growing Places Fund, primarily focused on projects which will unlock commercial land and / or jobs. Investments are a mixture of grants and loans, providing a recycling fund.',
        'primarysector': 'All',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 65000,
        'startdate': 2015,
        'directjobcreationforecast': 320,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 320,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 520
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Signpost to Grant - CPCA Growth Hub',
        'description': 'Small capital grant scheme to enhance SME competitiveness, create employment, improve productivity and exports',
        'primarysector': 'All',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 120000,
        'startdate': 2016,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Not available'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'COVID Capital Growth Grant Scheme',
        'description': 'SME support grants - capital expenditure to support businesses through the current pandemic',
        'primarysector': 'All',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 3000000,
        'startdate': 2020,
        'directjobcreationforecast': 287,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 287,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Peterborough Builds Back Better',
        'description': 'Reimagination of Peterborough City Centre to stimulate a cafe culture in Cathedral Square. The aim of the cafe culture facility will be to attract additional private sector investment in the city, create or protect unto 100 jobs in the city. ',
        'primarysector': 'All',
        'postcode': 'PE1 ',
        'coordinates': '52.57296425048848, -0.24309558646378512',
        'firstline': 'City Centre',
        'grantamount': 800000,
        'startdate': 2020,
        'directjobcreationforecast': 100,
        'indirectjobcreationforecast': 200,
        'totaljobcreationforecast': 300,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Start-Ups, Scale-Ups & Set-Ups​',
        'name': 'Cambridge Visitor Welcome 2021',
        'description': 'facilitation of street closures to support the recovery of the city centre post COVID, includes redesign of areas to include outdoor seating',
        'primarysector': 'All',
        'postcode': 'CB1 ',
        'coordinates': '52.19784188971941, 0.1664356490987854',
        'firstline': 'City Centre',
        'grantamount': 710000,
        'startdate': 2020,
        'directjobcreationforecast': 60,
        'indirectjobcreationforecast': 380,
        'totaljobcreationforecast': 440,
        'defaultcolor': '#6d4000',
        'checkedcolor': '#A06B19',
        'icon': 'gold-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Hauxton House Incubation Centre',
        'description': 'Refit and refurbishment of a grade 2 listed mill to support the development of  of incubator/clean lab space at Hauxton House',
        'primarysector': 'Life Science',
        'postcode': 'CB22 5HX',
        'coordinates': '52.142584298810014, 0.10485667524249509',
        'firstline': 'Mill Scitech park, Mill Lane',
        'grantamount': 438000,
        'startdate': 2019,
        'directjobcreationforecast': 64,
        'indirectjobcreationforecast': 46,
        'totaljobcreationforecast': 110,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'South Fenland Enterprise Park ',
        'description': 'The project will deliver new flexible grow-on or ‘scale-up’ business space at Chatteris in Fenland, adding a third phase to the established start-up and small business hub at the South Fens Enterprise Park.',
        'primarysector': 'Business Growth',
        'postcode': 'PE15 8NQ',
        'coordinates': '52.5580960325304, 0.08970707920647916',
        'firstline': 'South Fenland Enterprise Park , by A142',
        'grantamount': 997032,
        'startdate': 2020,
        'directjobcreationforecast': 30,
        'indirectjobcreationforecast': 46,
        'totaljobcreationforecast': 76,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Photocentric 3D Centre of Excellence ',
        'description': 'Development of a 3D manufacturing farm producing innovative products via 3D production printing methods',
        'primarysector': 'Business Growth',
        'postcode': 'PE1 5YW',
        'coordinates': '52.58956260577184, -0.19891512919222723',
        'firstline': 'Cambridge House, Oxney Road',
        'grantamount': 1875000,
        'startdate': 2020,
        'directjobcreationforecast': 616,
        'indirectjobcreationforecast': 61,
        'totaljobcreationforecast': 677,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Cambridge Biomedical Campus ',
        'description': 'Develop a 122,642 sq. ft. gross multi-occupancy building, able to accommodate requirements ranging from 5,000 - 20,000 sq. ft. and incorporating a mix of dry and wet laboratory space and ancillary office, estimated at a total cost of £48m including professional fees and infrastructure. ',
        'primarysector': 'Life Science',
        'postcode': 'CB2 0SL',
        'coordinates': '52.17372534998756, 0.13798442857996318',
        'firstline': '277 Hills Road',
        'grantamount': 3000000,
        'startdate': 2020,
        'directjobcreationforecast': 880,
        'indirectjobcreationforecast': 2204,
        'totaljobcreationforecast': 3084,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'NIAB - AgriTech Start Up Incubator',
        'description': 'Refit and refurbishment of Barn 4 - development of SME start up space focused on AgriTech industry',
        'primarysector': 'AgriTech',
        'postcode': 'CB3 0LE',
        'coordinates': '52.222245183700934, 0.0959893670875274',
        'firstline': '93 Lawrence Weaver road',
        'grantamount': 2484000,
        'startdate': 2020,
        'directjobcreationforecast': 947,
        'indirectjobcreationforecast': 770,
        'totaljobcreationforecast': 1717,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 44
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'NIAB - Agri-Gate Hasse Fen extension',
        'description': 'Further development of the incubator space focussing on AgriTech companies, linked to the heat regenration and green energy opportunities in the farming industry',
        'primarysector': 'AgriTech',
        'postcode': 'CB7 5UW',
        'coordinates': '52.36723337736752, 0.35799319833955795',
        'firstline': 'Hasse Road',
        'grantamount': 599850,
        'startdate': 2020,
        'directjobcreationforecast': 65,
        'indirectjobcreationforecast': 100,
        'totaljobcreationforecast': 165,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'TWI Engineering Centre ',
        'description': 'A new secure fabrication, testing and validation facility for materials engineering, supporting a range of industries including oil, gas, energy, aerospace/defence and rail. This facility has been developed at TWI’s headquarters on Granta Park and the capital project includes the purchase of specialist fabrication and testing equipment. Ultimately the project will result in innovation in materials fabrication and in turn further research, jobs and skills outcomes.',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'CB21 6AL',
        'coordinates': '52.11770560979441, 0.22594325813167837',
        'firstline': 'Granta Park',
        'grantamount': 2100000,
        'startdate': 2015,
        'directjobcreationforecast': 20,
        'indirectjobcreationforecast': 35,
        'totaljobcreationforecast': 55,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 30
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Biomedical Innovation Centre ',
        'description': 'Led by University of Cambridge, Growth Deal investment supported the conversion of part of a building on the Biomedical Campus at Addenbrookes into a new innovation centre for NHS and University spin-outs and start-ups wishing to be part of the southern Cambridge med-tech cluster. LEP investment will result in new companies, new technologies, jobs and leverage.',
        'primarysector': 'Life Science',
        'postcode': 'CB2 0SL',
        'coordinates': '52.17372534998756, 0.13798442857996318',
        'firstline': 'Dame Mary Archer way',
        'grantamount': 1000000,
        'startdate': 2015,
        'directjobcreationforecast': 162,
        'indirectjobcreationforecast': 81,
        'totaljobcreationforecast': 243,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 80
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Haverhill Epicentre - Jaynic',
        'description': 'Development of a building to house incubator/start-ups focused on life science on the outskirts of Haverhill',
        'primarysector': 'Life Science',
        'postcode': 'CB9 7FB',
        'coordinates': '52.09586298911997, 0.40477585671402716',
        'firstline': 'Enterprise Way',
        'grantamount': 2600000,
        'startdate': 2019,
        'directjobcreationforecast': 300,
        'indirectjobcreationforecast': 450,
        'totaljobcreationforecast': 750,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'TWI Ecosystem Innovation Centre',
        'description': 'Refurbishment of office space for startup companies, offering support and access to facilities',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'CB21 6AL',
        'coordinates': '52.11770560979441, 0.22594325813167837',
        'firstline': 'Granta Park',
        'grantamount': 1230000,
        'startdate': 2020,
        'directjobcreationforecast': 2,
        'indirectjobcreationforecast': 75,
        'totaljobcreationforecast': 77,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'West Cambs Innovation Park',
        'description': 'The vision for West Cambridge is to pilot a new approach for enabling business growth and scale up across the UK, using an integrated model of planning and business development to replicate global best practice and utilise the enormous anchorage potential of world-class British universities.',
        'primarysector': 'Life Science',
        'postcode': 'CB2 1TN',
        'coordinates': '52.205121680300806, 0.11640324195478344',
        'firstline': 'Denny Road',
        'grantamount': 3000000,
        'startdate': 2020,
        'directjobcreationforecast': 380,
        'indirectjobcreationforecast': 150,
        'totaljobcreationforecast': 530,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'TTP Life Sciences Incubator',
        'description': 'TTP plans to create a life science incubator on its site at Melbourn Science Park to support the formation and growth of new life science start-ups on the Cambridgeshire/Hertfordshire border. This incubator will combine specialist facilities, TTP\'s proven track record in assisting start-ups throughout their life cycle and investment in these start-ups secured from TTP\'s global commercial network. ',
        'primarysector': 'Life Science',
        'postcode': 'SG8 6EE',
        'coordinates': '52.09001558550282, 0.02485215380114987',
        'firstline': 'Melbourne Science Park',
        'grantamount': 2300000,
        'startdate': 2020,
        'directjobcreationforecast': 236,
        'indirectjobcreationforecast': 10,
        'totaljobcreationforecast': 246,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Hi-Tech Jobs Growth',
        'name': 'Aracaris Capital Living Cell Centre',
        'description': 'Development of state of the art clean labs, office space focussed on the living cell medical breakthrough fro treatment of cancer and other genetically influenced diseases',
        'primarysector': 'Life Science',
        'postcode': 'CB22 3JG',
        'coordinates': '52.12711111766944, 0.1474961967838545',
        'firstline': 'Whitefield Way',
        'grantamount': 1350000,
        'startdate': 2019,
        'directjobcreationforecast': 200,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 200,
        'defaultcolor': '#260036',
        'checkedcolor': '#511A61',
        'icon': 'purple-office',
        'totalJobCreationactual': 'Pending'
    },
    {
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Whittlesey King\'s Dyke Crossing',
        'description': 'Creation of a new vehicular bridge over the A605 between Whittlesey and Peterborough, to improve travel time by closing the current inefficient level crossing and creating an alternate route. Will create growth opportunities resulting in jobs and homes.',
        'primarysector': 'Transport',
        'postcode': 'PE7 1UF',
        'coordinates': '52.55086482940724, -0.12152007317360736',
        'firstline': 'Kings Dyke',
        'grantamount': 8000000,
        'startdate': 2016,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Bourges Boulevard Phase 1',
        'description': 'Improvements to Bourges Boulevard in Peterborough, an important connection between two main city centre roundabouts. Updating of the road layout improved connections between the railway station and commercial centre, providing a new pedestrian walk-through. It has also contributed to the City Council\'s ability to create a land assembly site for future mixed use.',
        'primarysector': 'Transport',
        'postcode': 'PE1 2AD',
        'coordinates': '52.57744287679804, -0.2516123977127775',
        'firstline': 'Peterborough City Centre',
        'grantamount': 2100000,
        'startdate': 2014,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Bourges Boulevard Phase 2',
        'description': 'Further improvements to the southern streatch of Bourges Boulevard, to include a mix of interventions to increase road capacity, improve pedestrian and cycling opportunities and improve road safetly. This will also improve links between the Southbank development site and city centre, delivering new homes, car parking, commercial space, creative hubs and a hotel.',
        'primarysector': 'Transport',
        'postcode': 'PE1 1QL',
        'coordinates': '52.57410960536948, -0.2493056699329206',
        'firstline': 'Peterborough City Centre',
        'grantamount': 9200000,
        'startdate': 2016,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'A47/A15 Junction 20',
        'description': 'Scheme to ease congestion at Junction 20 of the A47 North East of Peterborough, which is a key interchange on Peterborough’s Parkway witnessing increase of traffic in recent years. The roundabout is subject to heavy queuing during peak hours but these improvements and full signalisation of the junction will reduce this. Improvements will also allow for the completion of nearby developments (Paston Reserve and Norwood) and will provide a connection from these sites to the Parkway, resulting in significant numbers of new homes and new community infrastructure.',
        'primarysector': 'Transport',
        'postcode': 'PE4 7AP',
        'coordinates': '52.612852112088945, -0.2427617485031945',
        'firstline': 'Paston Ridings',
        'grantamount': 6300000,
        'startdate': 2016,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Wisbech Access Stategy',
        'description': 'This project is split into two phases - initial investment of £1m into feasibility study which will provide a way forward to deliver a further £10.5m package of transport schemes in and around Wisbech. These will improve transport capacity in turn unlocking sites in Feland\'s Local Plan which will deliver jobs and homes.',
        'primarysector': 'Transport',
        'postcode': 'PE13 1AR',
        'coordinates': '52.66597164765246, 0.16198341128718396',
        'firstline': 'Wisbech Bypass',
        'grantamount': 6000000,
        'startdate': 2015,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 1500,
        'totaljobcreationforecast': 1500,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Lancaster Way Phase 1 Loan',
        'description': 'Construction of 9 small business units in an Enterprise Zone on the outskirts of Ely',
        'primarysector': 'Business Growth',
        'postcode': 'CB6 3NW',
        'coordinates': '52.38213926636139, 0.22060139964037445',
        'firstline': 'Lancaster Way Business Park',
        'grantamount': 1000000,
        'startdate': 2017,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 180,
        'totaljobcreationforecast': 180,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 511
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Lancaster Way Phase 2 Loan',
        'description': 'Phase 2 infrastucture to support growth in Enterprise Zone',
        'primarysector': 'Transport',
        'postcode': 'CB6 3NW',
        'coordinates': '52.38213926636139, 0.22060139964037445',
        'firstline': 'Lancaster Way Business Park',
        'grantamount': 3680000,
        'startdate': 2017,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 180,
        'totaljobcreationforecast': 180,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction'
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Lancaster Way Phase 2 Grant',
        'description': 'Improved road access to Enterprise Zone to support future growth potential',
        'primarysector': 'Transport',
        'postcode': 'CB6 3NW',
        'coordinates': '52.38213926636139, 0.22060139964037445',
        'firstline': 'Lancaster Way Business Park',
        'grantamount': 1455000,
        'startdate': 2020,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 180,
        'totaljobcreationforecast': 180,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction'
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Ely Southern Bypass',
        'description': 'Creation of a bypass around Ely to reduce congestion at the train station level crossing and provide a new link between Stutney Causeway and Angel Drove to the south of the city. This will prevent large queues which currently form when traffic on the slip-road blocks access to the underpass. This new bypass will also enable redevelopment and growth of the Station Gateway area. Outputs will include significant homes and jobs numbers.',
        'primarysector': 'Transport',
        'postcode': 'CB6 3HL',
        'coordinates': '52.37647385952841, 0.2533011863806083',
        'firstline': 'Ely Bypass',
        'grantamount': 22000000,
        'startdate': 2016,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Manea & Whittlesea Stations',
        'description': 'Feasibility study into the xtensions to railway platforms to increase capacity',
        'primarysector': 'Transport',
        'postcode': 'PE15 0HG',
        'coordinates': '52.4959889578545, 0.18418648483275282',
        'firstline': 'Station Rd - Manea\nStation rd - Whittlesey',
        'grantamount': 395000,
        'startdate': 2017,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'In Progress',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'CAM Promotion Company',
        'description': 'A JV company to promote and support the development of the Cambridge Automated Metro to build stage, bringing in expert advisors, and private funding where possible.',
        'primarysector': 'Transport',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 999000,
        'startdate': 2021,
        'directjobcreationforecast': 60,
        'indirectjobcreationforecast': 33,
        'totaljobcreationforecast': 93,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 'Pending'
    },
    {
        'status': 'Delivered',
        'layer': 'Accelerating Recovery in Construction',
        'name': 'Soham Station',
        'description': 'Development of a new station covering Soham, improving transport links across the area',
        'primarysector': 'Transport',
        'postcode': 'CB3 0AP',
        'coordinates': '52.21305953887275, 0.11420704195513426',
        'grantamount': 1000000,
        'startdate': 2019,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#003867',
        'checkedcolor': '#006295',
        'icon': 'blue-construction',
        'totalJobCreationactual': 0
    },
    {
        'status': 'In Progress',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'Metalcraft Advanced Manufacturing Centre',
        'description': 'The creation of an Innovation Launchpad will act as a nucleation point for innovation cluster development and business growth. Provision of a new training centre to meet the needs of local and wider area businesses to address the lack of training facilities for apprentices. ',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'PE16 6UY',
        'coordinates': '52.44616318524984, 0.06004580254389492',
        'firstline': 'Chatteris Business Park',
        'grantamount': 3160000,
        'startdate': 2020,
        'directjobcreationforecast': 14,
        'indirectjobcreationforecast': 624,
        'totaljobcreationforecast': 638,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'University of Peterborough Phase 1 ',
        'description': 'The project will establish a Phase 1 University Campus in Peterborough, for 2,000 students by September 2022, with a curriculum and delivery model that is designed to meet the skills needs that growth in the Greater Peterborough business base will generate. ',
        'primarysector': 'Multi-Sector',
        'postcode': 'PE1 5AP',
        'coordinates': '52.5721985915433, -0.23248083712900705',
        'firstline': 'Bishop Road',
        'grantamount': 12500000,
        'startdate': 2020,
        'directjobcreationforecast': 250,
        'indirectjobcreationforecast': 14000,
        'totaljobcreationforecast': 14250,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'March Adult Education Skills & Training Expansion',
        'description': 'To provide for the development of 4 workshops and 3 additional classrooms at its new main centre in March. ',
        'primarysector': 'Multi-Sector',
        'postcode': 'PE15 8LE',
        'coordinates': '52.552227413685856, 0.09043638826608384',
        'firstline': '34 Station Road',
        'grantamount': 400000,
        'startdate': 2020,
        'directjobcreationforecast': 48,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 48,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'Delivered',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'PRC Food Manufacturing Centre',
        'description': 'Growth Deal funding supported the creation of a new dedicated manufacturing training Centre of Excellence, meeting local training needs in food manufacturing. The project will deliver skills and apprenticeships outcomes.',
        'primarysector': 'Food Processing',
        'postcode': 'PE1 4DZ',
        'coordinates': '52.587188699995444, -0.22985514532836604',
        'firstline': 'Park Crescent',
        'grantamount': 586000,
        'startdate': 2015,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 0,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 0
    },
    {
        'status': 'Delivered',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'iMET Skills Training Centre',
        'description': 'iMET is a new state of the art training facility under construction at Alconbury Weald Enterprise Zone near Huntingdon which is due to open fully in Autumn 2018. Led by Cambridgeshire Regional College, the centre will deliver technical, advanced and higher vocational skills in manufacturing, engineering, advanced construction and high-technology industries resulting in a range of qualifications and apprenticeships, to serve employers throughout the whole of the GCGP area.',
        'primarysector': 'Advanced Manufacturing',
        'postcode': 'PE28 4WX',
        'coordinates': '52.380393503443536, -0.22142855886224155',
        'firstline': 'Alconbury Weald',
        'grantamount': 10500000,
        'startdate': 2015,
        'directjobcreationforecast': 1,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 1,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 2
    },
    {
        'status': 'Delivered',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'CITB Construction Academy ',
        'description': 'Growth Deal has supported the establishment of a new construction training facility using simulators, which is among the first of its kind in the country to be integrated into established apprenticeship programmes and mainstream provision. The simulators enable training to continue in poor weather and they produce detailed analysis of trainees\' progress, enhancing assessment.',
        'primarysector': 'Construction',
        'postcode': 'PE31 6RH',
        'coordinates': '52.873597305324495, 0.6562964971579701',
        'firstline': 'Bircham Newton',
        'grantamount': 450000,
        'startdate': 2016,
        'directjobcreationforecast': 1,
        'indirectjobcreationforecast': 0,
        'totaljobcreationforecast': 1,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 2
    },
    {
        'status': 'In Progress',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'CRC Construction Skills Hub ',
        'description': 'Refurbshiment of the current construction facility at Huntingdon Regional College, to expand and develop the space to provide a better and safer learning environment. Digital improvements to communicaiton between college and students',
        'primarysector': 'Construction',
        'postcode': 'PE29 1BL',
        'coordinates': '52.33899793194797, -0.17816849037195148',
        'firstline': 'California Road',
        'grantamount': 2500000,
        'startdate': 2020,
        'directjobcreationforecast': 9,
        'indirectjobcreationforecast': 60,
        'totaljobcreationforecast': 69,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 'Pending '
    },
    {
        'status': 'In Progress',
        'layer': 'Retraining & Upskilling for New Jobs​',
        'name': 'AEB Innovation Grant',
        'description': 'Grants supporting colleges and training providers in developing innovative ways to engage and supoport adult learners',
        'primarysector': 'Multi-Sector',
        'postcode': 'CB7 4LS',
        'coordinates': '52.40059754552448, 0.26416464449709964',
        'firstline': '72 Market Street',
        'grantamount': 323720,
        'startdate': 2020,
        'directjobcreationforecast': 0,
        'indirectjobcreationforecast': 50,
        'totaljobcreationforecast': 50,
        'defaultcolor': '#347700',
        'checkedcolor': '#67A61E',
        'icon': 'green-office',
        'totalJobCreationactual': 'Pending '
    }
];

export default Source;