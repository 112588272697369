import './App.css';
import { useState, useEffect, useRef } from 'react';
import LayerMenu from './Menu/LayerMenu';

import CustomIcons from './map-assets/CustomIcons';
import AddPopup from './MapComponents/Popup';
import { SourceData, Years } from './map-data/MapData';

import { isMobile } from 'react-device-detect';


import mapboxgl from 'mapbox-gl';
// // eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_DEV_TOKEN;
} else {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PROD_TOKEN;
}

function MapBox() {

    const [mapConfig, setMapConfig] = useState(null);

    useEffect(() => {

        const mapBox = new mapboxgl.Map({
            container: 'MapBoxContainer',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [
                isMobile ? 0.16422901750833927 : 0.26422901750833927,
                isMobile ? 52.50056481521905 : 52.40056481521905],
            zoom: isMobile ? 8 : 9,
        });

        CustomIcons.forEach(icon => {
            let customIcon = isMobile ? new Image(48, 48) : new Image(36, 36);
            customIcon.onload = () => mapBox.addImage(icon.name, customIcon);
            customIcon.src = icon.src;
        });

        mapBox.on('load', () => {

            mapBox.addControl(new mapboxgl.NavigationControl(), 'top-right');

            SourceData.map(layer => {

                const { layerName, visible, features } = layer;

                const featureMap = features.map(feature => ({
                    'type': 'Feature',
                    'properties': {
                        ...feature
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': feature.coordinates
                    }
                }));

                // Source = geojson Data
                mapBox.addSource(layerName, {
                    type: 'geojson',
                    data: {
                        'type': 'FeatureCollection',
                        features: featureMap
                    },
                    'cluster': true,
                    'clusterRadius': isMobile ? 9 : 5,
                });
                // Layer = Splops
                mapBox.addLayer({
                    'id': layerName,
                    'type': 'symbol',
                    'source': layerName,
                    'layout': {
                        // make layer visible by default
                        'visibility': visible,
                        'icon-image': ['get', 'icon'],
                        'icon-padding': 0,
                        'icon-ignore-placement': true, // Allows, no affect on collisions
                        // 'icon-allow-overlap': false, // Prevents Overlap, no affect on collisions
                    },
                });

                /**********************
                 * Map Event Handlers *
                 **********************/
                mapBox
                    .on('click', layerName, (e) => {
                        var coordinates = e.features[0].geometry.coordinates.slice();

                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        const removePopup = AddPopup(e.features[0], coordinates, mapBox);

                        if (window._PopUpsHandles) {
                            window._PopUpsHandles.push(removePopup);
                        } else {
                            window._PopUpsHandles = [removePopup];
                        }

                    })
                    .on('mouseenter', layerName, evt => {
                        mapBox.getCanvas().style.cursor = 'pointer';
                    })
                    .on('mouseleave', layerName, () => {
                        mapBox.getCanvas().style.cursor = '';
                    });

                return void 0;
            });

        });

        mapBox.resize();
        setMapConfig(mapBox);

    }, []);

    return (
        <div>
            <LayerMenu layers={SourceData} years={Years} mapExport={mapConfig}/>
            <div id="MapBoxContainer"  className="MapBoxContainer" />
        </div>
    );
}

export default MapBox;