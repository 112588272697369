import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/button';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles((theme) => ({
    clearButton: {
        width: '100%'
    },
}));

const ClearFilters = ({ years, layers, mapExport, setToggleState, setInternalVals }) => {

    const classes = useStyles();

    const clickHandler = () => {

        // SEE: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfilter
        setToggleState({});
        setInternalVals();
        layers.map( ({ layerName }) => {
            mapExport.setFilter(layerName, null);
            mapExport.setLayoutProperty(layerName, 'visibility', 'visible');
        });
    };

    return (

        <Button
            variant="outlined"
            className={clsx(classes.clearButton)}
            startIcon={<RotateLeftIcon />}
            onClick={clickHandler}
        >
            Reset Filters
        </Button>
    );
};

export default ClearFilters;