import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ColorSwitch = ({
    toggleState,
    setToggleState,
    colors,
    custIndex,
    layer,
    mapExport,
}) => {

    const useSwitchStyles = makeStyles(() => ({
        switchBase: {
            color: colors.default,
            '&$checked': {
                color: colors.checked,
            },
            '&$checked + $track': {
                backgroundColor: colors.checked,
            },
        },
        checked: {},
        track: {},
    }));

    const switchClass = useSwitchStyles();

    const handleChange = (key, evt) => {
        setToggleState({ ...toggleState, [layer.layerName]: evt.target.checked });
        const visibility = evt.target.checked ? 'visible' : 'none';
        mapExport.setLayoutProperty(layer.layerName, 'visibility', visibility);
    };

    return (
        <Switch
            classes={switchClass}
            key={custIndex}
            defaultValue={true}
            checked={ toggleState.hasOwnProperty(layer.layerName) ? toggleState[layer.layerName] : true }
            onChange={(evt) => handleChange(layer.layerName, evt)}
        />
    );
};

export default ColorSwitch;