import GreenOffice from './green-office.svg';
import BlueOffice from './blue-office.svg';
import PurpleOffice from './purple-office.svg';
import { ReactComponent as PurpleOfficeComp } from './purple-office.svg';
import GoldOffice from './gold-office.svg';
import { ReactComponent as GoldOfficeComp } from './gold-office.svg';

import GreenConstruction from './green-construction.svg';
import { ReactComponent as GreenConstructionComp } from './green-construction.svg';
import BlueConstruction from './blue-construction.svg';
import { ReactComponent as BlueConstructionComp } from './blue-construction.svg';
import PurpleConstruction from './purple-construction.svg';
import { ReactComponent as PurpleConstructionComp } from './purple-construction.svg';
import GoldConstruction from './gold-construction.svg';

const CustomIcons = [{
        src: GreenOffice,
        name: 'green-office',
        comp: GreenConstructionComp,
    },
    {
        src: BlueOffice,
        name: 'blue-office'
    },
    {
        src: PurpleOffice,
        name: 'purple-office',
        comp: PurpleOfficeComp,
    },
    {
        src: GoldOffice,
        name: 'gold-office',
        comp: GoldOfficeComp,
    },
    {
        src: GreenConstruction,
        name: 'green-construction'
    },
    {
        src: BlueConstruction,
        name: 'blue-construction',
        comp: BlueConstructionComp
    },
    {
        src: PurpleConstruction,
        name: 'purple-construction',
        comp: PurpleConstructionComp,
    },
    {
        src: GoldConstruction,
        name: 'gold-construction'
    }
];

export default CustomIcons;