import React, { useState, useEffect } from 'react';

import {
    isBrowser,
    isMobile
} from 'react-device-detect';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import {
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemText,
    Typography,
    IconButton,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';

import ColorSwitch from './Custom/ColorSwitch';
import ColorFilterSwitch from './Custom/ColorFilterSwitch';
import ColorDivider from './Custom/ColorDivider';
import ClearFilters from './Filters/ClearFilters';
import YearFilter from './Filters/YearFilter';
import ValueFilter from './Filters/ValueFilter';

import { Years, GrantValues, TotalJobCreationValues } from '../map-data/MapData';

const drawerWidth = isMobile ? '100%' : 475;

const useStyles = makeStyles((theme) => ({
    blurb: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '1em',
    },
    list: {
        width: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        color: 'black',
        backgroundColor: 'white',
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        backgroundColor: '#008486',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        minHeight: '3em !important',
    },
    mapFilterContainer: {
        width: '85%',
        textAlign: 'center',
        margin: '1em auto'
    },
    headerWrapper: {
        flexGrow: 1,
        textAlign: 'center',
        color: 'white',
        fontSize: '1.4em',
    },
}));

let yearSlider, valueSliders;

export default function LayerMenu({ layers, mapExport }) {

    const classes = useStyles();

    // Shared state for toggles on the nav menu
    const [toggleState, setToggleState] = useState({});
    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (window._PopUpsHandles && window._PopUpsHandles.length && drawer === false) {
            window._PopUpsHandles.map( removePopup => removePopup() );
            window._PopUpsHandles = [];
        }

        setDrawer(!drawer);
    };

    const setInternalVals = () => (yearSlider = 0, valueSliders = 0);

    const OpenMenuButton = () => (
        <IconButton
            style={{backgroundColor: '#008486', margin: '1em', display: drawer ? 'none' : 'block'}}
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer()}
            className={classes.menuButton}
        >
            <MenuIcon />
        </IconButton>
    );

    const HeaderText = ({ navDrawerOpen, shouldShow }) => (
        <div className={classes.headerWrapper}>
            <span className={clsx(navDrawerOpen === shouldShow && classes.hide)} >
                    Local Growth Fund Projects
            </span>
        </div>
    );

    const SubNavFragments = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
        >
            <List>
                {
                    layers.map( (layer, index) => (
                        <div key={layer.layerName}>
                            <ColorDivider colors={layer.colours}/>
                            <ListItem>
                                <ListItemText primary={layer.layerName} />
                                <ColorSwitch
                                    layer={layer}
                                    mapExport={mapExport}
                                    custIndex={index}
                                    toggleState={toggleState}
                                    setToggleState={setToggleState}
                                    colors={layer.colours}
                                />
                            </ListItem>
                        </div>
                    ))
                }
                <Divider />
            </List>
        </div>
    );

    const HeaderBlurb = () => (
        <div className={classes.blurb}>
            <Typography variant="body1" gutterBottom>
                The Local Growth Fund provides capital funding via the Business Board, the Local Enterprise Partnerships for the region, which invests in local projects that help overcome strategic barriers to growth. The Business Board allocates funding with the potential to drive inclusive growth, deliver improved infrastructure and stimulate the regional economy.
            </Typography>
        </div>
    );

    const DrawerHeader = () => (
        <div className={classes.drawerHeader}>
            <HeaderText navDrawerOpen={drawer} shouldShow={false}/>
            <IconButton size="small" onClick={toggleDrawer()} style={{backgroundColor: 'white'}}>
                <ChevronLeftIcon color="primary" style={{color: '#008486'}}/>
            </IconButton>
        </div>
    );

    const MapFilters = () => {

        const [yearFilter, setYearFilter] = useState(yearSlider ? yearSlider : 0);
        const [valueFilter, setValueFilter] = useState(valueSliders ? valueSliders : 0);

        useEffect(() => (yearSlider = yearFilter), [yearFilter]);

        useEffect(() => (valueSliders = valueFilter), [valueFilter]);

        useEffect( () => mapBoxFilter(), [toggleState] );

        const mapBoxFilter = () => {

            const filterArray = [];

            if (yearFilter) {
                filterArray.push(
                    ['>=', ['get', 'startDate'], yearFilter[0]],
                    ['<=', ['get', 'startDate'], yearFilter[1]],
                );
            };

            if (toggleState) {
                const { delivered, inProgress } = toggleState;
                const hasDeliveredState = toggleState.hasOwnProperty('delivered');
                const hasProgressState = toggleState.hasOwnProperty('inProgress');
                const shouldShowDelivered = !hasDeliveredState || (hasDeliveredState && delivered);
                const shouldShowInProgress = !hasProgressState || hasProgressState && inProgress;
                switch (true) {
                    case shouldShowDelivered && shouldShowInProgress:
                        break;
                    case !shouldShowDelivered && !shouldShowInProgress:
                        filterArray.push(['==', ['get', 'status'], 'NA']);
                        break;
                    case shouldShowDelivered:
                        filterArray.push(['==', ['get', 'status'], 'Delivered']);
                        break;
                    case shouldShowInProgress:
                    filterArray.push(['==', ['get', 'status'], 'In Progress']);
                        break;
                }
            }

            if (valueFilter) {
                FilterProps.map(filter => {
                    const { name, dataName } = filter;
                    if (valueFilter.hasOwnProperty(name)) {
                        filterArray.push(['<=', ['get', dataName], valueFilter[name]]);
                    };
                });
            };

            layers.map(({ layerName }) => {
                mapExport.setFilter(
                    layerName,
                    ['all', ...filterArray],
                );
            });
        };

        const GetFilterProps = (props) => {
            const { name, dataSet, dataName } = props;

            return {
                name,
                dataSet,
                dataName,
                valueFilter,
                setValueFilter,
                mapBoxFilter,
            };
        };

        const FilterProps = [
            { name: 'Grant Amount', dataSet: GrantValues, dataName: 'grantAmount' },
            { name: 'Total Job Creation Forecast', dataSet: TotalJobCreationValues, dataName: 'totalJobCreationForecast' },
        ].map(filter => GetFilterProps(filter));

        const GetToggleProps = (props) => {
            const { toggleName, toggleLabel } = props;

            return {
                toggleState,
                setToggleState,
                toggleName,
                toggleLabel,
            };
        };

        const ToggleProps = [
            { toggleName: 'inProgress', toggleLabel: 'In Progress' },
            { toggleName: 'delivered', toggleLabel: 'Delivered' },
        ].map( toggle => GetToggleProps(toggle) );

        return (
            <div className={classes.mapFilterContainer}>

                <YearFilter
                    name={'YearRange'}
                    years={Years}
                    yearFilter={yearFilter}
                    setYearFilter={setYearFilter}
                    mapBoxFilter={mapBoxFilter}
                />

                {FilterProps.map( filter => <ValueFilter key={filter.name} {...filter} /> )}

                <FormGroup row style={{marginTop: '-1.2em', marginBottom: '1em'}}>
                    {ToggleProps.map( toggle => (
                        <FormControlLabel
                            key={`${toggle.toggleLabel}-label`}
                            style={{flexGrow: 1, justifyContent: 'center', margin: '0 0'}}
                            control={ <ColorFilterSwitch {...toggle} /> } label={toggle.toggleLabel}
                        />
                    ) )}
                </FormGroup>

                <ClearFilters years={Years} layers={layers} mapExport={mapExport} setToggleState={setToggleState} setInternalVals={setInternalVals}/>

            </div>
        );
    };

    return (
        <div id="drawerContainer" style={{position: 'relative', zIndex: '100000'}} >

            <OpenMenuButton />

            <Drawer
                open={drawer}
                onClose={toggleDrawer()}
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{
                    container: document.getElementById('drawerContainer'),
                    style: { position: 'absolute' }
                }}
            >
                <DrawerHeader />
                <HeaderBlurb />
                <SubNavFragments />

                <MapFilters />

            </Drawer>
        </div>
    );
}