import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const LGSlider = withStyles({
    root: {
        color: '#008486',
        height: 1,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        '& *': {
            background: '#008486',
            color: '#fff',
        },
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const YearFilter = ({
    years,
    yearFilter,
    setYearFilter,
    mapBoxFilter,
}) => {

    const minMark = Math.min(...years);
    const maxMark = Math.max(...years);
    const step = 1;

    const marks = [minMark, maxMark].map(year => ({
        value: year,
        label: year,
    }));

    // SEE: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfilter

    const handleChange = (event, newValue) => {
        setYearFilter(newValue);
        mapBoxFilter();
    };

    return (
        <div id="range-slider">
            <Typography gutterBottom>
                Project Start Date
            </Typography>
            <LGSlider
                id={'range-slider'}
                step={step}
                min={minMark}
                max={maxMark}
                value={yearFilter.length ? yearFilter : [minMark, maxMark]}
                onChange={handleChange}
                valueLabelDisplay="auto"
                marks={marks}
            />
        </div>
    );
};

export default YearFilter;