import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CommaFormattedNumber } from '../Utils/Tools';
import CustomIcons from '../map-assets/CustomIcons';

import {
    Card,
    Button,
    Icon,
    SvgIcon,
    CardContent,
    CardActions,
    Typography,
    Table,
    TableBody,
    TanbleBody,
    TableCell,
    TableRow,
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
    },
    typography: {
        fontSize: 13,
        fontFamily: [
            'Lato',
            'serif',
        ].join(','),
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 325,
    },
    button: {
        marginLeft: 'auto',
        color: 'white',
        backgroundColor: '#008486',
        '&:hover': {
            backgroundColor: '#4eb4b6',
        },
        marginBottom: '0.6em',
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        margin: '1em auto',
    },
    // description: {
    //     fontSize: 12.5,
    // },
    header: {
        textAlign: 'center',
    },
    imageIcon: {
        height: '100%',
        marginTop: '1em',
    },
    iconRoot: {
        textAlign: 'center'
    }
}));

const Modal = ({ feature }) => {

    const {
        name,
        // description,
        primarySector,
        postCode,
        grantAmount,
        startDate,
        directJobCreationForecast,
        indirectJobCreationForecast,
        totalJobCreationForecast,
        icon,
    } = feature.properties;

    const tableData = [
        { name: 'Primary Sector', value: primarySector },
        { name: 'Post Code', value: postCode },
        { name: 'Grant Amount', value: `£${CommaFormattedNumber(grantAmount)}` },
        { name: 'Start Date', value: startDate },
        { name: 'Direct Job Creation (Forecast)', value: directJobCreationForecast },
        { name: 'Indirect Job Creation (Forecast)', value: indirectJobCreationForecast },
        { name: 'Total Job Creation (Forecast)', value: totalJobCreationForecast },

    ];

    const classes = useStyles();
    const CustIcon = CustomIcons.filter( custIcon => custIcon.name === icon )[0].comp;

    return (

        <MuiThemeProvider theme={theme}>
            <Card className={classes.root}>

                <CardContent className={classes.header}>

                    <SvgIcon>
                        <CustIcon />
                    </SvgIcon>

                    <Typography className={classes.title} variant="h2" component="h2" gutterBottom>
                        {name}
                    </Typography>

                    {/* <Typography className={classes.description} gutterBottom>
                        {description.split('.')[0].replace(/(\.|\s)$/,'')}
                    </Typography> */}

                    <Table size="small" aria-label="simple table">
                      <TableBody className={classes.table}>
                          {tableData.map( row => (
                              <TableRow key={row.name}>
                                <TableCell style={{fontSize: 13, fontWeight: 500}} component="th" scope="row">{row.name}</TableCell>
                                <TableCell style={{fontSize: 13}} align="right">{row.value}</TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                </CardContent>

                <CardActions>
                    <Button className={classes.button} variant="outlined" size="medium">READ MORE</Button>
                </CardActions>

            </Card>
        </MuiThemeProvider>
    );

};

const AddPopup = (feature, coordinates, plugin) => {
    const placeholder = document.createElement('div');
    ReactDOM.render(<Modal feature={feature} />, placeholder);

    const marker = new mapboxgl.Popup()
        .setDOMContent(placeholder)
        .setLngLat(coordinates)
        .setMaxWidth('325px')
        .addTo(plugin);


    return marker.remove;
};

export default AddPopup;